<template>
  <el-dialog :title="title" :visible.sync="dialogFormVisible" width="45%">
    <el-form
        ref="form"
        :model="form"
        label-position="right"
        :rules="rules"
        class="info"
    >
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item
              label="职位名称"
              :label-width="formLabelWidth"
              prop="jobName"
          >
            <el-input
                v-model="form.jobName"
                autocomplete="off"
                placeholder="请输入职位名称"
                :disabled="disable"
                maxlength="12"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="薪资范围"
              :label-width="formLabelWidth"
              prop="salaryRange"
          >
            <!-- <el-input v-model="form.salary" autocomplete="off" placeholder="请输入薪资范围" ></el-input> -->
            <el-select v-model="form.salaryRange" placeholder="请选择薪资范围" :disabled="disable" filterable >
              <el-option
                  v-for="item in salaryList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="联系人"
              :label-width="formLabelWidth"
              prop="userLiaisonName"
          >
            <el-select v-model="form.userLiaisonName" placeholder="请选择联系人"
                       @change="handleLiaisonName"
                       :disabled="disable"
                       filterable
            >
              <el-option
                  v-for="item in cList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="联系电话"
              :label-width="formLabelWidth"
              prop="userLiaisonPhone"
          >
            <el-input
                v-model="form.userLiaisonPhone"
                autocomplete="off"
                placeholder="请输入联系电话"
                :disabled="disable"
                maxlength="11"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item
              label="负责人"
              :label-width="formLabelWidth"
              prop="userName"
          >
            <el-select v-model="form.userName"
                       :disabled="disable"
                       @change="handleFuzeren"
                       placeholder="请选择负责人"
                       filterable >
              <el-option
                  v-for="item in hList"
                  :key="item.id"
                  :label="item.realName"
                  :value="item.realName"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="负责人电话"
              :label-width="formLabelWidth"
              prop="liaisonNameSale"
          >
            <el-input
                v-model="form.liaisonNameSale"
                autocomplete="off"
                placeholder="请输入负责人电话"
                :disabled="disable"
                maxlength="11"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              label="司机性质"
              :label-width="formLabelWidth"
              prop="driverNature"
          >
            <el-select v-model="form.driverNature"
                       :disabled="disable"
                       placeholder="请选择司机性质"
                       filterable >
              <el-option
                  v-for="item in driverNatureList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.label"
              >
              </el-option>
            </el-select>

          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注" :label-width="formLabelWidth" prop="remarks">
            <el-input
                :disabled="disable"
                v-model="form.remarks"
                type="textarea"
                rows="6"
                maxlength="200"
                show-word-limit
                resize="none"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-footer>
      <el-col :span="24" style="text-align: right">
        <el-button type="primary" @click="close" plain>取消</el-button>
        <el-button type="primary" @click="handleSave" v-if="!this.form.id">保存</el-button>
      </el-col>
    </el-footer>
  </el-dialog>
</template>

<script>
import {getDict} from "@/utils/api/dict";
import {getCList as getContactList} from '@/utils/api/contact'
import {getResponsible} from '@/utils/api/dict'
import {addJob} from "@/utils/api/job";


export default {
  name: "EditPosition",
  data() {
    return {
      disable: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      title: "新增",
      formLabelWidth: "100px",
      labelPosition: "right",
      cList: [], //联系人列表
      hList: [], //负责人列表
      salaryList: [], //薪资列表
      driverNatureList: [], //司机性质列表
      form: {
        jobName: "", //姓名
        salaryRange: "", //薪资范围
        userLiaisonName: "", //联系人
        userLiaisonPhone: "", //联系电话
        // userNameSaleUserId: "", //负责人编号
        userName: "", //负责人姓名
        liaisonNameSale: "", //负责人电话
        driverNature: '', //司机性质
        remarks: "", //备注
      },
      rulePosition: {},
      rules: {
        jobName: [
          { required: true, message: '请输入职位名称', trigger: 'blur' },
        ],
        salaryRange: [
          { required: true, message: '请选择薪资范围', trigger: 'blur' },
        ],
        userLiaisonName: [
          { required: true, message: '请输选择联系人', trigger: 'blur' },
        ],
        userLiaisonPhone: [
          { required: true, message: '请输入联系人手机号', trigger: 'blur' },
        ],
        userName: [
          { required: true, message: '请选择负责人名称', trigger: 'blur' },
        ],
        liaisonNameSale:[
          { required: true, message: '请输入负责人手机号', trigger: 'blur' },
        ],
        driverNature: [
          { required: true, message: '请选择职位性质', trigger: 'blur' },
        ],
        remarks: [
          { required: true, message: '请输入职位详情', trigger: 'blur' },
        ],

      }
    };
  },

  methods: {
    showEdit(row) {
      if (!row) {
        this.title = "新增职位信息";
      } else {
        this.title = "编辑职位信息";
        this.form = Object.assign({}, this.form, row);
      }
      this.dialogFormVisible = true;
    },
    checkDetail(row) {
      this.title = "查看职位信息";
      this.form = Object.assign({}, this.form, row);
      this.disable = true
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
      this.$emit("close");
    },
    save() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          // const { msg } = await doEdit(this.form)
          // this.$baseMessage(msg, 'success')
          // this.$refs['form'].resetFields()
          // this.dialogFormVisible = false
          this.$emit("save");
          this.form = this.$options.data().form;
        } else {
          return false;
        }
      });
    },
    getDict() {
      let data = {
        code: "XZFW",
      };
      getDict(data).then((res) => {
        // this.salaryList =
        if (res.code === 0) {
          this.salaryList = res.data;
        }
      });
    },
    getDictSJSX() {
      let data = {
        code: "SJSX",
      };
      getDict(data).then((res) => {
        // this.salaryList =
        if (res.code === 0) {
          this.driverNatureList = res.data;
        }
      });
    },
    //获取联系人
    getContactList() {
      // this.cList
      getContactList().then((res) => {
        if (res.code === 0) {
          this.cList = res.data;
        }
      });
    },
    //获取负责人
    getResponsible() {
      getResponsible().then((res) => {
        if (res.code === 0) {
          this.hList = res.data;
        }
      });
    },
    //选择联系人时绑定电话
    handleLiaisonName(value) {
      this.cList.map((item) => {
        if (item.name === value) {
          this.form.userLiaisonPhone = item.telePhone;
        }
      });
    },
    //选择负责人时绑定电话
    handleFuzeren(value) {
      this.hList.map(item => {
        if(item.realName === value){
          this.form.liaisonNameSale = item.userPhone
          this.form.userNameSaleUserId = item.id
        }
      })
    },
    handleSave() {
      let data = this.form
      addJob(data).then(res => {
        if (res.code === 0) {
          this.$emit('getPosition')
          this.close()
          this.$message.success("职位添加成功")
        }else{
          this.$message.error(res.msg)
        }
      })
    }
  },
  created() {
    this.getDict();
    this.getContactList();
    this.getResponsible();
    this.getDictSJSX()
  },
};
</script>

<style  lang="less">
.el-dialog__body {
  .el-input {
    width: 100%;
  }
  .el-select{
    width: 100%;
  }
}
</style>
