 <template>
  <div class="index-container">
    <div class="bread"> <span @click="home()">操作台 / </span><span class="gray">职位管理</span></div>
    <el-row class="table-body">
      <div class="line clearfix">
        <h5 class="fl title">职位管理</h5>
        <div class="fr search">
          <el-input size="small" type="text" placeholder="请输入职位" v-model="search.jobName" prefix-icon="el-icon-search"></el-input>
          <el-input size="small" type="text" placeholder="请输入负责人" v-model="search.userLiaisonName"></el-input>
          <el-button type="primary" size="small" @click="getPosition">查询</el-button>
          <el-button type="primary" size="small" plain @click="addNew">新增</el-button>
        </div>
      </div>
      <div class="list">
        <el-tabs v-model="pState" @tab-click="handleClick">
          <el-tab-pane label="全部" name="0"></el-tab-pane>
          <el-tab-pane label="已发布" name="1"></el-tab-pane>
          <el-tab-pane label="已完成" name="2"></el-tab-pane>
        </el-tabs>
        <el-table
            :data="tableData"
            style="width: 100%">
          <el-table-column
              fixed
              type="index"
              label="序号"
              width="120"
          >
          </el-table-column>
          <el-table-column
              prop="jobName"
              label="职位名称"
              min-width="120"
          >
          </el-table-column>
          <el-table-column
              prop="salaryRange"
              label="薪资范围"
              min-width="200"
          >
          </el-table-column>
          <el-table-column
              prop="userLiaisonName"
              label="联系人"
              min-width="150"
          >
          </el-table-column>
          <el-table-column
              prop="driverNature"
              label="司机性质"
              min-width="120"
          >
            <template slot-scope="scope">
              <span class="red">{{scope.row.driverNature}}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="remarks"
              label="备注"
              min-width="400">
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="200">
            <template slot-scope="scope">
              <el-button
                  @click="checkDetail(scope.row)"
                  type="text"
                  size="small">
                查看详情
              </el-button>
              <el-button
                  @click="handleJobOk(scope.row)"
                  type="text"
                  size="small"
                v-if="scope.row.jobStatus === 1"
              >
                标记完成
              </el-button>
              <el-button
                  type="text"
                  size="small"
                  v-if="scope.row.jobStatus === 1"
                  @click="handleDelete(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pages">
        <el-pagination
            background
            :hide-on-single-page="showPage"
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="total">
        </el-pagination>
      </div>
    </el-row>
    <edit-position ref="position" @getPosition="getPosition"></edit-position>
  </div>
</template>

<script>
import EditPosition from '@/components/EditPosition';
import {deleteJob, getJobPage, jobOk} from "@/utils/api/job";

export default {
  name: "position",
  components: {
    EditPosition
  },
  data() {
    return {
      total: 0, //总条数
      pageNo: 1,
      pageSize: 10,
      showPage: true,
      pState: 0,
      tableData: [
        // {
        //   pid:1,
        //   name:"职位名称",//职位名称
        //   salary:{
        //     min:100,
        //     max:1000
        //   },//薪资范围
        //   contact:'',//联系人编号
        //   contact_name:'',//联系人
        //   contact_phone:"",//联系人电话
        //   head:"",//负责人编号
        //   head_name:"",//负责人
        //   head_phone:"",//负责人电话
        //   nature:1,//司机性质
        //   note:"福利多，待遇好，专职招司机，做五休二，多劳多得"
        // },
      ], //表格数据

      search: {
        jobName: "",//搜索车牌
        userLiaisonName: "",//负责人
      },

    }
  },
  methods: {
    home(){
      this.goHome()
    },
    handleClick() {
      this.getPosition()
    },
    //新增职位
    addNew() {
      this.$refs['position'].showEdit()
    },
    //编辑职位
    editOne(row) {
      this.$refs['position'].showEdit(row)
    },
    //查看
    checkDetail(row) {
      this.$refs['position'].checkDetail(row)
    },
    handleDelete(value) {
      this.$confirm('您将删除该条职位, 确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: value.id
        }
        deleteJob(data).then(res => {
          if (res.code === 0) {
            this.$message.success("删除成功")
            this.getPosition()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    getPosition() {
      let data = {
        "jobName": "",
        "userName": "",
        "type": this.pState,
        "page": this.pageNo,
        "rows": this.pageSize,
      }
      if(this.search.userLiaisonName !== ''){
        data.userName =  this.search.userLiaisonName
      }
      if(this.search.jobName !== ''){
        data.jobName =  this.search.jobName
      }
      getJobPage(data).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    //标记职位完成
    handleJobOk(value){
      this.$confirm('您将该职位标记为完成, 确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: value.id
        }
        jobOk(data).then(res => {
          if (res.code === 0) {
            this.$message.success("操作成功")
            this.getPosition()
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    }
  },
  created() {
    this.getPosition()
  }
}
</script>

<style  lang="less">
.table-body {
  background-color: #FFFFFF;
  padding: 30px;
  padding-top: 0;

  .line {
    height: 90px;
    line-height: 90px;

    .title {
      font-size: 16px;
      font-weight: 800;
      color: #333333;
    }

    .search {
      display: flex;
      align-items: center;
      align-content: center;
      justify-items: flex-end;
      .el-input {
        font-size: 12px;
        margin-right: 12px;
        padding-left: 10px;
        .el-input__inner {
          border: none;
          background-color: #FAFAFA;
        }

      }

    }
  }
}
</style>
