/**
 * 批量上传
 * @param params
 * @returns {AxiosPromise}
 */
import http from "../http"
//发起调用ocr
export const addJob = (params) => {
    return http.postRequest(`/tJob/add`, params)
}

//获取职位列表
export const getJobPage = (params) => {
    return http.postRequest(`/tJob/page`, params)
}

//获取职位列表
export const deleteJob = (params) => {
    return http.postUrlParams(`/tJob/delete`, params)
}

//获取职位列表
export const jobOk = (params) => {
    return http.postUrlParams(`/tJob/jobOk`, params)
}

